.recipients-list::-webkit-scrollbar {
    width: 5px;               /* ширина scrollbar */
}
.recipients-list::-webkit-scrollbar-track {
    background: transparent;        /* цвет дорожки */
}
.recipients-list::-webkit-scrollbar-thumb {
    background-color: rgb(0, 140, 255);    /* цвет плашки */
    border-radius: 11px;       /* закругления плашки */
    border: 1px solid rgb(0, 140, 255);  /* padding вокруг плашки */
}

.recipients-list::-o-scrollbar {
    width: 5px;               /* ширина scrollbar */
}
.recipients-list::-o-scrollbar-track {
    background: transparent;        /* цвет дорожки */
}
.recipients-list::-o-scrollbar-thumb {
    background-color: rgb(0, 140, 255);    /* цвет плашки */
    border-radius: 11px;       /* закругления плашки */
    border: 1px solid rgb(0, 140, 255);  /* padding вокруг плашки */
}

.recipients-list::-moz-scrollbar {
    width: 5px;               /* ширина scrollbar */
}
.recipients-list::-moz-scrollbar-track {
    background: transparent;        /* цвет дорожки */
}
.recipients-list::-moz-scrollbar-thumb {
    background-color: rgb(0, 140, 255);    /* цвет плашки */
    border-radius: 11px;       /* закругления плашки */
    border: 1px solid rgb(0, 140, 255);  /* padding вокруг плашки */
}
@media screen and (width > 500px) {
    .recipients-list {
        margin-left: 20px;
        width: 90%;
        height: 80%;
        overflow-y: scroll;
    }
    .recipient-item {
        padding: 12px;
        width: 95%;
        height: 75px;
        border-radius: 11px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(0, 0, 0, 0.5);
        margin-top: 2%;
        margin-left: 20px;
    }
    .recipient-item-right-side {
        width: 300px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .recipient-item-img {
        display: inline-block;
        height: 70px;
        width: 70px;
        object-fit: cover;
        border-radius: 50%;
    }
    .recipient-item-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 20px;
    }
    .recipient-item-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
}
@media screen and (width < 500px) {
    .recipients-list {
        margin-left: 5px;
        width: 100%;
        height: 80%;
        overflow-y: scroll;
    }
    .recipient-item {
        padding: 12px;
        width: 85%;
        height: 33px;
        border: 1px solid black;
        border-radius: 11px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(0, 0, 0, 0.5);
        margin-top: 3%;
    }
    .recipient-item-right-side {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .recipient-item-img {
        display: inline-block;
        height: 30px;
        width: 30px;
        object-fit: cover;
        border-radius: 50%;
    }
    .recipient-item-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 10px;
    }
    .recipient-item-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
}