.services-list {
    background-color: rgba(41, 41, 41, 0.75);
    padding: 10px;
    margin: 10px;
    margin-top: 10vh;
    height: 50vh;
    border-radius: 12px;
}
.service {
    width: 50px;
    margin: 15px;
    padding: 10px;
    border: 1px solid rgb(0, 140, 255);
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
}
.service-img {
    height: 40px;
}
.service-title {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 8pt;
}