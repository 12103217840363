:root {
  --blue-neon: #40a4ff;
  --red-neon: #ff073a;
  --green-neon: #86ed26;
}

.common-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.common-modal-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
  border-radius: 15px;
  position: relative;
  max-height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: var(--blue-neon) rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.5);
}

.common-modal-close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.common-modal-body {
  width: 100%;
}

.common-modal-neutral {
  border: 0.5px solid var(--blue-neon);
  color: var(--blue-neon);
}

.common-modal-success {
  border: 0.5px solid var(--green-neon);
  color: var(--green-neon);
}

.common-modal-error {
  border: 0.5px solid var(--red-neon);
  color: var(--red-neon);
}

@media screen and (width >= 1080px) {
  .common-modal-content-width {
    width: 50%;
  }
}

@media screen and (width >= 1920px) {
  .common-modal-content-width {
    width: 500px;
  }
}

@media screen and (width < 1080px) {
  .common-modal-content-width {
    width: 50%;
  }
}

@media screen and (width <= 600px) {
  .common-modal-content-width {
    width: 96%;
  }
}

.common-modal-content::-webkit-scrollbar {
  width: 8px;
}

.common-modal-content::-webkit-scrollbar-thumb {
  background-color: var(--blue-neon);
  border-radius: 10px;
}

.common-modal-content::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.5);
}
