@media screen and (width > 700px) {
  .homepage {
    width: 100%;
    height: auto;
    z-index: 10;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-size: cover;
    background-attachment: fixed;
  }
  .homelinks {
    padding-top: 50px;
    padding-bottom: 100px;
    background-color: rgba(0, 0, 0, 0.654);
    margin: 0;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .homelink {
    color: rgba(255, 255, 255, 0.48);
    font-size: 20pt;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
    margin-bottom: 40px;
    width: 100%;
  }
  .homelink:hover {
    color: white;
  }
}

@media screen and (width < 700px) and (width > 500px) {
  .homepage {
    position: absolute;
    margin-top: -100px;
    width: 100%;
    height: 111%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-size: cover;
    background-attachment: fixed;
  }
  .homelinks {
    height: 113%;
    margin: 0;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 100px;
  }
  .homelink {
    color: rgba(255, 255, 255, 0.48);
    font-size: 20pt;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
    margin-bottom: 40px;
  }
  .homelink:hover {
    color: white;
  }
}

@media screen and (width < 500px) {
  .homepage {
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-size: cover;
    background-attachment: fixed;
    /*margin-top: -50px;*/
    z-index: 2;
  }
  .homelinks {
    margin: 0;
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.654);
    z-index: 1;
    padding-top: 60px;
    margin-top: -60px;
  }
  .homelink {
    color: rgba(255, 255, 255, 0.48);
    font-size: 20pt;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
    margin-bottom: 20px;
  }
  .homelink:hover {
    color: white;
  }
}
