.ad-item {
    width: 25%;
    height: 300px;
    background-color: rgb(41, 41, 41);
    border: 1px solid rgb(83, 83, 83);
    border-radius: 12px;
    padding: 5px;
    margin-left: 10px;
    margin-bottom: 5px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}
.ad-item-image {
    width: 98%;
    height: 50%;
    object-fit: cover;
    border-radius: 12px;
}
.ad-item-images {
    height: 75px;
    padding: 10px;
}
.ad-item-small-image {
    height: 100%;
    border: 1px solid rgb(0, 140, 255);
}
.ad-item-info {
    color: white;
    text-align: left;
    margin: 10px;
}
.ad-item-title {
    font-family: Arial, Helvetica, sans-serif;
    margin: 1px;
}
.ad-item-description {
    font-size: 14pt;
    color: white;
}
.ad-item-price {
    color: rgb(0, 140, 255);
    font-size: 14pt;
    margin: 1px;
}
.ad-item-city {
    font-size: 12pt;
    margin: 1px;
    color: rgb(0, 140, 255)
}
.ad-item-date {
    font-size: 10pt;
    font-family: 'Courier New', Courier, monospace;
    color:rgb(83, 83, 83);
}
