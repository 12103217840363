.create-game { 
    margin: 0 auto;
    width: 30%;
    margin-top: 5%;
    border: 1px solid rgb(0, 140, 255);
    border-radius: 11px;
    padding: 10px;
    background-color: rgb(41, 41, 41);
    border: 1px solid rgb(83, 83, 83);
    border-radius: 12px;
}
.create-game-title {
    color: white;
    font-size: 14pt;
    font-family: Arial, Helvetica, sans-serif;
}
.create-game-input {
    padding: 10px;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 2px solid rgb(0, 140, 255);
    width: 95%;
    margin-bottom: 10px;
    color: white;
}
.create-game-area {
    padding: 10px;
    background-color: transparent;
    outline: none;
    border: 1px solid rgb(0, 140, 255);
    border-radius: 12px;
    width: 95%;
    height: 60px;
    margin-bottom: 10px;
    color: white;
}
.create-game-button {
    width: 100%;
    border: none;
    outline: none;
    background-color: rgb(0, 140, 255);
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    padding: 12px;
    border-radius: 12px;
    margin-top: 5px;
}
.create-game-empty-button {
    width: 100%;
    border: 1px solid rgb(0, 140, 255);
    outline: none;
    background-color: white;
    color: rgb(0, 140, 255);
    font-family: Arial, Helvetica, sans-serif;
    padding: 12px;
    border-radius: 12px;
    margin-top: 5px;
}
.create-game-empty-button:hover {
    background-color: rgb(0, 140, 255);
    color: white;
}
