@media screen and (width > 500px) {
  .room-item {
    background-color: rgb(0, 0, 0, 0.5);
    width: 150%;
    height: 55px;
    border-radius: 15px;
    padding: 15px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    color: white;
    transition: background-color 0.1 0.5;
    transition: color 0.1 0.5;
    transition: border 0.1 0.5;
    cursor: pointer;
  }
}
@media screen and (width < 500px) {
  .room-item {
    background-color: rgb(0, 0, 0, 0.5);
    width: 86%;
    height: 55px;
    border-radius: 15px;
    padding: 15px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-left: 10px;
    color: white;
    transition: background-color 0.1 0.5;
    transition: color 0.1 0.5;
    transition: border 0.1 0.5;
    cursor: pointer;
  }
}
@media screen and (width > 500px) {
  .chat-room-item {
    background-color: rgb(0, 0, 0, 0.5);
    border: 1px solid rgb(0, 140, 255);
    width: 200%;
    height: 55px;
    border-radius: 10px;
    padding: 15px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    color: white;
    transition: background-color 0.1 0.5;
    transition: color 0.1 0.5;
    transition: border 0.1 0.5;
    cursor: pointer;
  }
}
@media screen and (width < 500px) {
  .chat-room-item {
    background-color: rgb(0, 0, 0, 0.5);
    border: 1px solid rgb(0, 140, 255);
    width: 86%;
    height: 55px;
    border-radius: 10px;
    padding: 15px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-left: 10px;
    color: white;
    transition: background-color 0.1 0.5;
    transition: color 0.1 0.5;
    transition: border 0.1 0.5;
    cursor: pointer;
  }
}
.room-item:hover {
  background-color: rgb(0, 0, 0);
}
.chat-room-item:hover {
  background-color: rgb(0, 0, 0);
}
.room-item-blue {
  background-color: rgb(0, 140, 255);
  color: white;
  width: 100%;
  height: 55px;
  border-bottom: 1px solid #474747;
  border-radius: 10px;
  padding: 15px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgb(0, 140, 255);
  margin-bottom: 10px;
}
.chat-room-item-blue {
  background-color: rgb(0, 140, 255);
  border: 1px solid rgb(0, 140, 255);
  width: 245%;
  height: 55px;
  border-radius: 10px;
  padding: 15px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  color: white;
  transition: background-color 0.1 0.5;
  transition: color 0.1 0.5;
  transition: border 0.1 0.5;
  cursor: pointer;
}
.room-item-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.room-item-info-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.room-title {
  margin-left: 15px;
}
.room-img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
}
