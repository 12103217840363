@media screen and (width > 500px) {
    .videohost-channel-item {
        width: 84%;
        padding: 20px;
        border-bottom: 1px solid rgb(83, 83, 83);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
    }
}
@media screen and (width < 500px) {
    .videohost-channel-item {
        width: 98%;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
    }
}
.videohost-channel-item-preview {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid rgb(0, 140, 255);
}
.videohost-channel-item-title {
    color: white;
}
.videohost-channel-item-subscribers {
    color: white;
    opacity: .75;
}
.videohost-channel-item-button {
    color: white;
    border: none;
    padding: 13px;
    border-radius: 15px;
    background-color: rgb(0, 140, 255);
    margin-top: 10px;
    cursor: pointer;
}