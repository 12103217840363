@media screen and (width > 700px) {
  .room-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    flex-direction: column;
  }
  .rooms-list-wrapper {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
}
@media screen and (width < 700px) and (width > 500px) {
  .room-list {
    width: 60%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .rooms-list-wrapper {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
@media screen and (width < 500px) {
  .room-list {
    width: 98%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .rooms-list-wrapper {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.messenger-button {
  color: white;
  background-color: rgb(0, 140, 255);
  border: none;
  border-radius: 11px;
  cursor: pointer;
  padding: 8px;
  margin-bottom: 10px;
}
