.store-nav {
    width: 100%;
    height: 50px;
    margin-bottom: 100px;
}
.store-nav-title {
    color: white;
}
.store-nav-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin-left: 15px;
    margin-right: 15px;
}
.store-nav-links-left {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.store-nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
    margin-left: 10px;
}
.store-nav-link-name {
    color: white;
    font-size: 9pt;
}
.store-nav-link-icon {
    height: 100%;
}