.room-no-messages {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.room-no-messages-text {
  font-size: 24px;
  color: #999;
}
