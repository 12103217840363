@media screen and (width > 500px) {
    .product-form {
        background-color: rgba(41, 41, 41, 0.75);
        border: 1px solid rgb(83, 83, 83);
        border-radius: 12px;
        margin: 0 auto;
        margin-top: 10px;
        padding: 20px;
        width: 35%;
        height: 100vh;
    }
}
@media screen and (width < 500px) {
    .product-form {
        background-color: rgba(41, 41, 41, 0.75);
        border: 1px solid rgb(83, 83, 83);
        border-radius: 12px;
        margin: 0 auto;
        margin-top: 10px;
        padding: 10px;
        width: 98%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.product-form-title {
    color: white;
    font-size: 16pt;
    font-family: Arial, Helvetica, sans-serif;
}
.product-form-main-input {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid rgb(0, 140, 255);
    outline: none;
    color: white;
    width: 90%;
    padding: 10px;
    margin-bottom: 10px;
}
.product-admin-input {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid rgb(0, 140, 255);
    outline: none;
    color: white;
    width: 92%;
    padding: 10px;
    margin-bottom: 10px;
}
.product-form-input {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid rgb(0, 140, 255);
    outline: none;
    color: white;
    width: 40%;
    padding: 5px;
}
.product-form-area {
    background-color: transparent;
    border: 1px solid rgb(0, 140, 255);
    outline: none;
    color: white;
    border-radius: 12px;
    display: block;
    width: 90%; 
    padding: 5px;
    height: 100px;
}
.product-form-parameter {
    color: white;
    font-size: 12pt;
    display: block;
    width: 100%;
}
.product-form-select {
    color: rgb(0, 140, 255);
    border: 1px solid rgb(83, 83, 83);
    border-radius: 12px;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    padding: 10px;
}
.product-form-button {
    color: white;
    border: none;
    border-radius: 12px;
    outline: none;
    background-color: rgb(0, 140, 255);
    cursor: pointer;
    padding: 10px;
    margin: 10px;
}
.product-form-images-list {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}
.store-category {
    margin-top: 10px;
    color:rgb(0, 140, 255);
}
