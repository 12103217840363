.files {
    width: 32%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.654);
}
.files-head {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.3);
}
.files-body {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
}
.files-body::-webkit-scrollbar {
    width: 0px;
}
@media screen and (width > 500px) {
    .files-head-title {
        color: white;
        margin: 10px;
        font-weight: 100;
    }
    .upload-new-file {
        background-color: rgb(0, 140, 255);
        padding: 15px;
        border-radius: 15px;
        color: white;
        border: none;
        cursor: pointer;
    }
}
@media screen and (width < 500px) {
    .files-head-title {
        color: white;
        margin: 10px;
        font-weight: 100;
        font-size: 13pt;
    }
    .upload-new-file {
        background-color: rgb(0, 140, 255);
        padding: 15px;
        border-radius: 15px;
        color: white;
        border: none;
        cursor: pointer;
        position: fixed;
        top: 92%;
        left: 0%;
        width: 100%;
    }
}
.files-list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 10px;
}
