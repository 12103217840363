@media screen and (width > 500px) {
  .create-chat {
    margin: 0 auto;
    margin-top: 20px;
    width: 30%;
    border-radius: 11px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
@media screen and (width < 500px) {
  .create-chat {
    margin: 0 auto;
    margin-top: 10px;
    width: 98%;
    border-radius: 11px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.create-chat-title {
  color: white;
}
.create-chat-input {
  background-color: transparent;
  padding: 15px;
  color: white;
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(0, 140, 255);
  margin-bottom: 10px;
}
.create-chat-button {
  color: white;
  background-color: rgb(0, 140, 255);
  border: none;
  border-radius: 11px;
  cursor: pointer;
  padding: 13px;
}
