.game-rating {
    width: 100%;
    height: 50px;
}
.game-star {
    width: 50px;
    height: 50px;
}
.game-gray-star {
    width: 50px;
    height: 50px;
}

.game-red-star {
    width: 50px;
    height: 50px;
    filter: hue-rotate(40deg);
}
.game-orangered-star {
    width: 50px;
    height: 50px;
    filter: hue-rotate(80deg);
}
.game-orange-star {
    width: 50px;
    height: 50px;
    filter: hue-rotate(100deg);
}
.game-yelloworange-star {
    width: 50px;
    height: 50px;
    filter: hue-rotate(120deg);
}
.game-yellow-star {
    width: 50px;
    height: 50px;
    filter: hue-rotate(180deg);
}

