@media screen and (width > 500px) {
    .videohost-main-recommended-videos {
        width: 77%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .videohost-main {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 12px;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        margin: 0 auto;
        margin-top: 10px;
    }
    .videohost-main-recommended-channels {
        width: 23%;
    }
    .videohost-main-recommended-side {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-top: 1px solid rgb(83, 83, 83);
        width: 100%;
    }
    .videohost-main-subscribes {
        margin-top: 15px;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        border-top: 1px solid rgb(83, 83, 83);
    }
    .videohost-list {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
}
@media screen and (width < 500px) {
    .videohost-main-recommended-videos {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .videohost-main {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 12px;
        width: 96%;
        margin: 0 auto;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .videohost-main-recommended-channels {
        width: 0;
    }
    .videohost-main-recommended-side {
        margin-top: 10px;
        border-top: 1px solid rgb(83, 83, 83);
    }
    .videohost-list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .videohost-main-subscribes {
        margin-top: 15px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-top: 1px solid rgb(83, 83, 83);
    }
}
.videohost-title {
    margin-top: 40px;
    color: white;
    font-size: 14pt;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.videohost-title-small {
    margin-top: 20px;
    color: white;
    font-size: 10pt;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.videohost-link {
    color: rgb(0, 140, 255);
}
.videohost-text-left-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


@media screen and (width > 500px) {
    .videohost-navigation-link {
        text-decoration: none;
        background-color: transparent;
        border: none;
        border-right: 1px solid rgb(83, 83, 83);
        border-left: 1px solid rgb(83, 83, 83);
        color: rgb(0, 140, 255);
        padding: 15px;
        font-size: 11pt;
        transition: background-color 1s .1s;
    }
    .videohost-navigation {
        background-color: rgba(0, 0, 0, .3);
        padding: 15px;
        margin: 0;
        border-radius:  12px 12px 0 0;
        border-bottom: 1px solid rgb(83, 83, 83);
        width: 97.5%;
        margin-bottom: 20px;
    }
}
@media screen and (width < 500px) {
    .videohost-navigation-link {
        width: 92%;
        text-decoration: none;
        background-color: transparent;
        border: none;
        color: rgb(0, 140, 255);
        padding: 15px;
        font-size: 11pt;
        transition: background-color 1s .1s;
        display: block;
    }
    .videohost-navigation {
        background-color: rgba(0, 0, 0, .3);
        margin: 0;
        border-radius:  12px 12px 0 0;
        border-bottom: 1px solid rgb(83, 83, 83);
        width: 100%;
        margin-bottom: 20px;
    }
}
.videohost-navigation-link:hover {
    background-color: rgba(0, 0, 0, .5);
}



.videohost-search {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
@media screen and (width > 500px) {
    .videohost-search-field {
        border: 1px solid rgb(0, 140, 255);
        outline: none;
        background-color: rgba(0, 0, 0, .5);
        color: white;
        padding: 15px;
        margin-right: 10px;
        border-radius: 11px;
        width: 300px;
    }
}
@media screen and (width < 500px) {
    .videohost-search-field {
        border: 1px solid rgb(0, 140, 255);
        outline: none;
        background-color: rgba(0, 0, 0, .5);
        color: white;
        padding: 15px;
        margin-right: 10px;
        border-radius: 11px;
        width: 50%;
    }
}
.videohost-button {
    border: none;
    background-color: rgb(0, 140, 255);
    padding: 13px;
    color: white;
    border-radius: 12px;
    cursor: pointer;
}
.videohost-small-button {
    border: none;
    background-color: rgb(0, 140, 255);
    padding: 8px;
    color: white;
    border-radius: 13px;
    cursor: pointer;
    margin-left: 5px;
}

