@import url("./colors.css");

.room-smiles {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.smile-wrapper {
  display: inline-block;
  margin: 3px;
}
.smile-image {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  transition: filter 0.3s ease;
}

.smile-wrapper:hover .smile-image {
  filter: brightness(170%);
}
