.modal-overlay {
    background-color: rgba(0, 0, 0, 0.499);
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (width > 500px) {
    .modal-window {
        width: 50%;
        height: auto;
        background-color: rgb(20, 20, 32);
        border: 2px solid rgb(0, 140, 255);
        border-radius: 12px;
        padding: 0;
    }
}
@media screen and (width < 500px) {
    .modal-window {
        width: 98%;
        height: auto;
        background-color: rgb(20, 20, 32);
        border: 2px solid rgb(0, 140, 255);
        border-radius: 12px;
        padding: 0;
    }
}
.modal-title {
    color: rgb(0, 140, 255);
    font-size: 9pt;
}
.modal-main {
    padding: 15px;
    color: white;
}
.modal-text {
    font-size: 14pt;
}
.modal-cancel {
    border: 1px solid rgb(0, 140, 255);
    padding: 12px;
    color: rgb(0, 140, 255);
    border-radius: 13px;
    background-color: transparent;
    cursor: pointer
}
.modal-ok {
    border: none;
    padding: 13px;
    color: white;
    border-radius: 13px;
    background-color: rgb(0, 140, 255);
    cursor: pointer;
}
.modal-buttons {
    display: flex; 
    align-items: center;
    justify-content: space-between;
}