@media screen and (width > 500px) {
    .body {
        margin-top: 200px;
        margin-left: 40%;
        width: 60%;
        position: absolute;
        text-shadow: 1px 1px 2px black, 0 0 10em rgb(0, 140, 255);
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: rgba(0, 0, 0, 0.654);
        border-radius: 15px;
        margin-bottom: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 15px;
        padding-left: 15px;
        text-align: left;
        transition-property: transform;
        transition-duration: 0.6s;
        animation-name: glow;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }
    .daytime {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .time {
        font-weight: 100;
        font-size: 18pt;
        color: white;
        text-align: left;
    }
}
@media screen and (width < 500px) {
    .body {
        width: 100%;
        height: 100%;
        text-shadow: 1px 1px 2px black, 0 0 10em rgb(0, 140, 255);
        background-color: rgba(0, 0, 0, 0.654);
    }
    .head {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        z-index: 1;
    }
    .daytime {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .time {
        font-weight: 100;
        font-size: 20pt;
        color: white;
        text-align: center;
    }
}

@keyframes glow {
    0% {
      box-shadow: 0 0 7px #40A4FF;
    } 50% {
      box-shadow: 0 0 16px #40A4FF;
    } 100% {
      box-shadow: 0 0 8px #40A4FF;
    }
}
