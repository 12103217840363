.user-item {
  padding: 12px;
  width: 100%;
  height: 75px;
  border: 1px solid black;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 2%;
  cursor: pointer;
}
.user-item-selected {
  padding: 11px;
  width: 100%;
  height: 75px;
  border: 1px solid black;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(41, 41, 41);
  border: 2px solid rgb(0, 140, 255);
  margin-top: 1%;
  cursor: pointer;
}
.user-item-right-side {
  width: 60%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.user-item-img {
  display: inline-block;
  height: 70px;
  width: 70px;
  object-fit: cover;
  border-radius: 50%;
}
.user-item-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 20px;
}
.user-item-name {
  color: white;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.user-item-age {
  color: rgba(255, 255, 255, 0.674);
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.user-item-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
@media screen and (width > 500px) {
  .user-item-write-message {
    border-radius: 15px;
    padding: 12px;
    color: white;
    background-color: rgb(0, 140, 255);
    border: none;
  }
}
@media screen and (width < 500px) {
  .user-item-write-message {
    border-radius: 15px;
    padding: 2px;
    color: white;
    background-color: rgb(0, 140, 255);
    border: none;
  }
}
.user-item-request-sent {
  color: #40a4ff;
  font-size: 10pt;
  cursor: pointer;
}

.ml {
  margin-left: 10px;
}
