@media screen and (width > 500px) {
  .create-post {
    width: 30%;
    margin: 0 auto;
    margin-top: 10%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    padding: 10px;
  }
}
@media screen and (width < 500px) {
  .create-post {
    width: 90%;
    margin: 0 auto;
    margin-top: 5%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    padding: 10px;
  }
}
.new-smile {
  width: 30px;
  margin-top: 25px;
  filter: brightness(120%);
  cursor: pointer;
}
