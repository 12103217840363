@media screen and (width > 500px) {
  .form {
    width: 30%;
    padding: 12px;
    margin: 0 auto;
    margin-top: 20vh;
    margin-bottom: 10vh;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    transition-property: transform;
    transition-duration: 0.6s;
    animation-name: glow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
}
@media screen and (width < 500px) {
  .form {
    width: 90%;
    padding: 12px;
    margin: 0 auto;
    margin-top: 15px;
    background-color: rgba(0, 0, 0, 0.5);
    transition-property: transform;
    transition-duration: 0.6s;
    animation-name: glow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    border-radius: 12px;
  }
}
@keyframes glow {
  0% {
    box-shadow: 0 0 7px #40a4ff;
  }
  50% {
    box-shadow: 0 0 16px #40a4ff;
  }
  100% {
    box-shadow: 0 0 8px #40a4ff;
  }
}

.form-field {
  border: none;
  padding: 12px;
  background-color: transparent;
  outline: none;
  border-bottom: 2px solid #40a4ff;
  width: 94%;
  margin-top: 10px;
  color: white;
}
.form-area {
  border: 2px solid #40a4ff;
  padding: 12px;
  background-color: transparent;
  outline: none;
  width: 94%;
  height: 100px;
  margin-top: 10px;
  color: white;
  border-radius: 11px;
}
.submit {
  width: 100%;
  margin-top: 10px;
}
.form-title {
  color: white;
}
.theme-title {
  width: 100%;
  color: white;
  font-size: 16pt;
}
.theme-button {
  color: white;
  font-size: 12pt;
  padding: 7px 10px 5px 10px;
  background-size: cover;
  margin-left: 10px;
  border: none;
  cursor: pointer;
}
.theme-button-wb {
  color: white;
  font-size: 12pt;
  padding: 7px 10px 5px 10px;
  background-size: cover;
  margin-left: 10px;
  border: 1px solid white;
  cursor: pointer;
}
.theme-button:hover {
  padding: 6px 9px 6px 9px;
  border: 1px solid white;
}
.theme-button:visited {
  padding: 6px 9px 6px 9px;
  border: 1px solid white;
}
.theme-button-bg1 {
  background-image: url(../../homepage/img/backgrounds/city/1.jpg);
}
.theme-button-bg2 {
  background-image: url(../../homepage/img/backgrounds/mountain/1.jpg);
}
.theme-button-bg3 {
  background-image: url(../../homepage/img/backgrounds/nature/1.jpg);
}
.theme-button-bg4 {
  background-image: url(../../homepage/img/backgrounds/space/1.jpg);
}
