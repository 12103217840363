.context-menu {
  border: 1px solid #40a4ff;
  border-radius: 15px;
  background-color: black;
}

.menu-item {
  color: #40a4ff;
  cursor: pointer;
}
