.games-overlay {
    width: 100%;
    height: 100vh;
}
@media screen and (width > 500px) {
    .games {
        width: 60%;
        height: 100vh;
        margin: 0 auto;
        background-color: rgba(0, 0, 0, .5);
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 20px;
        border-radius: 15px;
    }
    .games-games {
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: -15%;
    }
    .games-img {
        margin-top: 20px;
        width: 25%;
    }
}
@media screen and (width < 500px) {
    .games {
        width: 98%;
        height: 100vh;
        margin: 0 auto;
        background-color: rgba(0, 0, 0, .5);
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 7px;
        border-radius: 15px;
    }
    .games-games {
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .games-img {
        width: 80%;
    }
}