.inner-ad-item {
    margin-top: 5px;
    width: 97%;
    background-color: rgb(41, 41, 41);
    border: 1px solid rgb(83, 83, 83);
    border-radius: 12px;
    padding: 5px;
    text-decoration: none;
    cursor: pointer;
    animation-name: appear;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
@media screen and (width > 500px) {
    .inner-ad-item-inline {
        margin-top: 5px;
        width: 20%;
        background-color: rgb(34, 34, 34);
        border: 1px solid rgb(83, 83, 83);
        border-radius: 12px;
        padding: 5px;
        text-decoration: none;
        cursor: pointer;
        display: inline-block;
    }
}
@media screen and (width < 500px) {
    .inner-ad-item-inline {
        margin-top: 5px;
        width: 98%;
        background-color: rgb(34, 34, 34);
        border: 1px solid rgb(83, 83, 83);
        border-radius: 12px;
        padding: 5px;
        text-decoration: none;
        cursor: pointer;
        display: inline-block;
    }
}
.inner-ad-item-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 12px;
}
.inner-ad-item-title {
    color: white;
    font-size: 12pt;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 10px;
    text-decoration: none;
}
.sd-none{
    text-decoration: none;
}
@keyframes appear {
    from {opacity: 0}
    to {opacity: 1;}
}
@keyframes disappear {
    from {opacity: 1}
    to {opacity: 0;}
}
