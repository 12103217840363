@import url("./colors.css");

.room-message-wrapper {
  width: 100%;
  margin: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.room-message {
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 0.5px solid var(--blue-neon);
  border-radius: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

@media screen and (width < 600px) {
  .room-message-width {
    width: 75%;
  }
}

@media screen and (width > 600px) {
  .room-message-width {
    width: 80%;
  }
}

.room-message-top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.room-message-avatar {
  width: 25px;
  height: 25px;
  display: inline-block;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 50%;
}

.room-message-name {
  text-align: left;
  font-size: 14pt;
}

.room-message-text {
  text-align: left;
  font-size: 13pt;
  margin-top: 10px;
}

.room-message-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 8px;
}

.room-message-date {
  font-size: 8pt;
  text-align: left;
  color: rgb(200, 200, 200);
  padding: 0;
  margin-left: -5px;
}

.room-message-actions-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
}

.room-message-action {
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.room-message-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 18px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
