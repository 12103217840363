.file-detail {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}
.file-detail-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
    width: 70%;
}
.file-table-head {
    border-bottom: 2px solid rgb(0, 140, 255);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    height: 30px;
    margin-top: -2px;
}
.file-table-body {
    border-bottom: 2px solid rgb(0, 140, 255);
    background-color: rgba(0, 0, 0, 0.3);
    color: rgb(0, 140, 255);
    height: 50px;
}
.file-table-head-title {
    width: 30%;
}
.download-link {
    text-decoration: none;
    padding: 15px;
    border-radius: 15px;
    color: white;
    border: none;
    cursor: pointer;
    margin: 5px;
    width: 50px;
}
@media screen and (width > 500px) {
    .open-download-link {
        text-decoration: none;
        padding-top: 10px;
        font-size: 11pt;
        background-color: rgba(9, 255, 0, 0.864);
        width: 140px;
    }
    .file-table {
        width: 100%;
    }
}
@media screen and (width < 500px) {
    .open-download-link {
        text-decoration: none;
        padding-top: 10px;
        font-size: 11pt;
        background-color: rgba(9, 255, 0, 0.864);
        width: 90%;
        position: fixed;
        top: 92%;
        left: 0%;
    }
    .file-table {
        width: 98%;
    }
}
.download-link:hover {
    border: 1px solid rgba(9, 255, 0, 0.864);
    color: rgba(9, 255, 0, 0.864);;
    background-color: transparent;
}
.button-blue:hover {
    border: 1px solid rgb(0, 140, 255);
    color: rgb(0, 140, 255);
    background-color: white;
    padding: 14px;
}
.button-blue {
    background-color: rgb(0, 140, 255);
}
.button-green {
    background-color: rgba(9, 255, 0, 0.864);
}
.button-green:hover {
    border: 1px solid rgba(9, 255, 0, 0.864);
    color: rgba(9, 255, 0, 0.864);
    background-color: white;
    padding: 14px;
}
.button-red {
    background-color: rgb(255, 0, 0);
    width: 80px;
}
.button-red:hover {
    border: 1px solid rgb(255, 0, 0);
    color: rgb(255, 0, 0);
    background-color: white;
    padding: 14px;
}
.file-actions {
    padding: 15px;
}
.file-preview {
    background-color: transparent;
    width: 100%;
    height: 400px;
    margin-top: 10px;
    padding: 0;
    overflow-y: scroll;
}
.file-preview-text {
    color: black;
    margin: 5px;
    margin-top: 10vh;
    text-align: left;
}
.file-img-dark-wrapper {
    background-color: transparent;
    width: 100%;
}
.file-opened-info {
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    height: 50%;
}
.file-opened-preview-name {
    color: white;
    font-weight: 100;
}