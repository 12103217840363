@media screen and (width > 500px) {
    .create-ad-page {
        margin-top: 5px;
        width: 40%;
        margin: 0 auto;
        background-color: rgb(41, 41, 41);
        border: 1px solid rgb(83, 83, 83);
        border-radius: 11px;
        padding: 10px;
        margin-top: 5px;
    }
}
@media screen and (width < 500px) {
    .create-ad-page {
        margin-top: 5px;
        width: 98%;
        margin: 0 auto;
        background-color: rgb(41, 41, 41);
        border: 1px solid rgb(83, 83, 83);
        border-radius: 11px;
        padding: 10px;
        margin-top: 5px;
    }
}
.create-ad-page-title {
    color: white;
    margin: 10px;
}
.create-ad-page-input {
    outline: none;
    border: none;
    color: white;
    border-bottom: 2px solid rgb(0, 140, 255);
    background-color: transparent;
    padding: 12px;
    margin: 10px;
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
    width: 40%;
}
.create-ad-page-input::-webkit-input-placeholder {color:rgb(0, 140, 255); opacity:1;}/* webkit */
.create-ad-page-input::-moz-placeholder          {color:rgb(0, 140, 255); opacity:1;}/* Firefox 19+ */
.create-ad-page-input:-moz-placeholder           {color:rgb(0, 140, 255); opacity:1;}/* Firefox 18- */

.create-ad-page-area {
    outline: none;
    border: 1px solid rgb(0, 140, 255);
    border-radius: 11px;
    background-color: transparent;
    color: white;
    padding: 12px;
    margin: 10px;
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
    width: 40%;
}
.create-ad-page-area::-webkit-input-placeholder {color:rgb(0, 140, 255); opacity:1;}/* webkit */
.create-ad-page-area::-moz-placeholder          {color:rgb(0, 140, 255); opacity:1;}/* Firefox 19+ */
.create-ad-page-area:-moz-placeholder           {color:rgb(0, 140, 255); opacity:1;}/* Firefox 18- */

.create-ad-page-button {
    color: white;
    background-color: rgb(0, 140, 255);
    padding: 12px;
    margin: 10px;
    border: none;
    border-radius: 12px;
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
}