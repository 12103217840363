.settings-wrapper {
  width: 98%;
  height: 98vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
@media screen and (width > 500px) {
  .settings {
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    width: 30%;
  }
  .settings-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
}
@media screen and (width < 500px) {
  .settings {
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    width: 98%;
  }
  .settings-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
