@media screen and (width > 500px) {
    .videohost-video-item {
        height: 150px;
        border-radius: 11px;
        padding: 5px;
        display: inline-block;
        cursor: pointer;
        margin: 10px;
        margin-bottom: 10px;
    }
}
@media screen and (width < 500px) {
    .videohost-video-item {
        width: 98%;
        height: 270px;
        border-radius: 11px;
        border: 1px solid rgb(83, 83, 83);
        background-color: rgb(41, 41, 41);
        padding: 5px;
        display: block;
        cursor: pointer;
        margin-bottom: 10px;
    }
}
.videohost-video-item-preview {
    width: 100%;
    height: 75%;
    object-fit: cover;
    border-radius: 11px;
    background-color: black;
}
.videohost-video-item-play {
    position: absolute;
    width: 100px;
    cursor: pointer;
    opacity: .8;
}
.videohost-video-item-play:hover {
    opacity: 1;
}
.videohost-video-item-author {
    color: rgb(0, 140, 255);
    text-align: left;
    cursor: pointer;
}
.videohost-video-item-title {
    color: white;
    text-align: left;
    font-size: 11pt;
}
.space {
    margin-top: 10px;
}
.videohost-video-item-text-block {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}